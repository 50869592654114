<template>
  <div class="main-content">
      <!-- 头 -->
      <div class="top" :style="{backgroundColor:isSkin}">
        <div class="thumb-title">教师认证</div>
      </div>
      <!-- 内容 -->
      <div class="content-view">
        <div class="zt" v-show="isShow != null">
          <div class="js" :style="{backgroundColor:isShow==1?isSkin:'#999'}">教师</div>
          <div class="yy">{{itemData.remarks}}</div>
          <div class="ztnr">
            <div class="zt1" v-if="isShow==0">审核中</div>
            <div class="zt1" v-if="isShow==1">审核通过</div>
            <div class="zt1" v-if="isShow==2">驳回</div>
            <div class="sj">{{itemData.updateTime}}</div>
          </div>
        </div>
        <!-- 介绍 -->
        <div class="top-title">
          <div>教师资格认证方式</div>
          <div>1.线上认证：用户提交“教师资格认证信息”，由出版社负责人进行认证。</div>
          <div>2.电话认证：用户拨打xxxx联系出版社，由出版社负责人为教师完成资格认证。</div>
        </div>
        <!-- 标题 -->
        <div class="title-rz">教师资格认证信息</div>
        <!-- 姓名 -->
        <div class="name">
          <div class="name-title">姓名：</div>
          <div>
            <input v-bind="{disabled:tJstatus}" class="input-view" v-model="name" placeholder="请输入姓名">
          </div>
        </div>
        <!-- 电话 -->
        <div class="phone">
          <div class="phone-title">电话：</div>
          <div>
            <input v-bind="{disabled:tJstatus}" class="input-view" v-model="phone" placeholder="请输入电话">
          </div>
        </div>
        <!-- 学校 -->
        <div class="schools">
          <div class="schools-title">所在学校：</div>
          <div>
            <input v-bind="{disabled:tJstatus}" class="input-view" v-model="school" placeholder="请输入所在学校">
          </div>
        </div>
        <!-- 系部名称 -->
        <div class="department">
          <div class="department-title">系部名称：</div>
          <div>
            <input v-bind="{disabled:tJstatus}" class="input-view" v-model="department" placeholder="请输入系部名称">
          </div>
        </div>
        <!-- 职务名称 -->
        <div class="posts">
          <div class="posts-title">职务名称：</div>
          <div>
            <input v-bind="{disabled:tJstatus}" class="input-view" v-model="posts" placeholder="请输入职务名称">
          </div>
        </div>
        <!-- 职称名称 -->
        <div class="titles">
          <div class="titles-title">职称名称：</div>
          <div>
            <input v-bind="{disabled:tJstatus}" class="input-view" v-model="titles" placeholder="请输入职称名称">
          </div>
        </div>
        <!-- 地址选择 -->
        <div class="choose">
          <div class="choose-title">所在地区：</div>
          <div class="select-one">
            <selectList :typeS="1" :list="provincesList" :placeholder="placeholder" @chooseMethod1="chooseMethod1"></selectList>
          </div>
          <div class="select-two">
            <selectList :typeS="2" :list="citysList" :placeholder="placeholder1" @chooseMethod2="chooseMethod2"></selectList>
          </div>
        </div>
        <!-- 详细地址 -->
        <div class="address">
          <div class="address-title">详细地址：</div>
          <div>
            <input v-bind="{disabled:tJstatus}" class="input-view" v-model="address" placeholder="请输入详细地址">
          </div>
        </div>
        <!-- 教师资格证 -->
        <div class="qualification">
          <div class="qualification-title">教师资格证：</div>
          <div class="qualification-img">
            <el-upload
              class="avatar-uploader"
              :action="actionURL"
              :show-file-list="false"
              :on-success="handleAvatarSuccess">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <!-- 保存 -->
        <div class="save" :style="{backgroundColor:isSkin}" @click.stop="contactMethod()">提交</div>
      </div>
  </div>
</template>

<script>
import {Message} from 'element-ui'
import selectList from './box/select-box'
import provinces from "../../../utils/province.js";
import citys from "../../../utils/city.js"
export default {
  components:{selectList},
  data() {
    return {
      itemData:{},
      isShow:null,
      placeholder:'选择省',
      placeholder1:'选择市',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      media:'',
      imageUrl:'',
      actionURL:'https://bookadmin.abook.cn/prod-api/app/upload/upload?token='+sessionStorage.getItem('token'),
      name:'',
      phone:'',
      school:'',
      department:'',
      posts:'',
      titles:'',
      address:'',
      qualification:'',
      selectList:[{
          value: '默认',
          label: '默认'
        },{
          value: '按点击量',
          label: '按点击量'
        },{
          value: '按收藏量',
          label: '按收藏量'
        }],
      provincesList:[],
      provinces: provinces,
      citysList:[],
      citys: citys[0],
      sheng:'',
      shi:'',
      tJstatus:false
    }
  },
  computed:{
    isSkin(){
		return this.$store.state.isSkin
    },
  },
  created () {
    console.log(this.$httpURL)
    this.getCTeacherCertification()
    this.getProvinces()
  },
  methods:{
    /**查看教师认证**/
    getCTeacherCertification(){
      var that = this
      that.$api.getCTeacherCertification({}).then(res=>{
        if (res.data.code == 0) {
           that.isShow = res.data.data.status
           that.itemData = res.data.data
          if (res.data.data.status == 2 || res.data.data.status == null) {
            that.tJstatus = false
            that.address = ''
            that.name = ''
            that.phone = ''
            that.school = ''
            that.department = ''
            that.posts = ''
            that.titles = ''
            that.imageUrl = ''
            that.placeholder = '选择省'
            that.placeholder1 = '选择市'
            sessionStorage.setItem('Teachers',1)
            this.$store.commit('changTeachers',1)
          }else{
            that.tJstatus = true
            that.address = res.data.data.address
            that.name = res.data.data.rname
            that.phone = res.data.data.phone
            that.school = res.data.data.school
            that.department = res.data.data.department
            that.posts = res.data.data.postName
            that.titles = res.data.data.title
            that.imageUrl = res.data.data.qualification
            that.placeholder = res.data.data.province
            that.placeholder1 = res.data.data.city
            sessionStorage.setItem('Teachers',2)
            this.$store.commit('changTeachers',2)
          }
        }else{
          console.log('3');
          that.tJstatus = false
          that.isShow = ''
        }
      })
    },
    /**初始化省内容**/ 
    getProvinces(){
      provinces.forEach((item,index) => {
        this.provincesList.push({
          dictLabel:item.label,
          dictValue:item.value
        })
      })
    },
    /**省选择按钮**/ 
    chooseMethod1(index,title,code){
      this.placeholder = ''
      this.placeholder1 = '选择市'
      this.sheng = title
      this.citysList = []
      this.citys = citys[index];
      this.citys.forEach((item,index) => {
        this.citysList.push({
          dictLabel:item.label,
          dictValue:item.value
        })
      })
    },
    /**市选择按钮**/ 
    chooseMethod2(index,title,code){
      this.placeholder1 = ''
      this.shi = title
    },
    /**上传图片成功回调**/ 
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data;
    },
    /**教师认证**/
    getTeacherCertification(){
      var that = this
      if (that.imageUrl == '') {
        Message({
          showClose: true,
          message: '请选择教师资格证',
          type: 'error'
        })
        return
      }
      that.$api.getTeacherCertification({
        address:that.address,
        city:that.shi,
        province:that.sheng,
        rname:that.name,
        phone:that.phone,
        school:that.school,
        department:that.department,
        postName:that.posts,
        title:that.titles,
        qualification:that.imageUrl
      }).then(res=>{
        if (res.data.code == 0) {
          this.getCTeacherCertification()
          Message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
        }else{
          this.getCTeacherCertification()
          Message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    /**保存按钮**/ 
    contactMethod(){
      var that = this
      if (that.name == '') {
        Message({
          showClose: true,
          message: '请输入姓名！',
          type: 'error'
        })
        return
			}
      if (that.phone == '') {
        Message({
          showClose: true,
          message: '请输入电话！',
          type: 'error'
        })
        return
			}
      if (that.school == '') {
        Message({
          showClose: true,
          message: '请输入学校！',
          type: 'error'
        })
        return
			}
      if (that.department == '') {
        Message({
          showClose: true,
          message: '请输入系部名称！',
          type: 'error'
        })
        return
			}
      if (that.posts == '') {
        Message({
          showClose: true,
          message: '请输入职务名称！',
          type: 'error'
        })
        return
			}
      if (that.titles == '') {
        Message({
          showClose: true,
          message: '请输入职称名称！',
          type: 'error'
        })
        return
			}
      if (this.tJstatus) {
        
      }else{
        this.getTeacherCertification()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main-content{
  width: 100%;
  .top{
    width: 100%;
    height: 55px;
    border-radius: 4px;
    line-height: 55px;
    .thumb-title{
      font-size: 18px;
      color: #FFFFFF;
      margin-left: 12px;
    }
  }
  .content-view{
    width: 100%;
    padding-bottom: 10px;
    .top-title{
      margin-left: 90px;
      margin-top: 50px;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
    .title-rz{
      font-size: 18px;
      line-height: 25px;
      color: #333333;
      margin-left: 90px;
      margin-top: 40px;
    }
    .name{
      margin-left: 90px;
      margin-top: 40px;
      display: flex;
      justify-items: center;
      align-items: center;
      .name-title{
        width: 84px;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
    }
    .phone{
      margin-left: 90px;
      margin-top: 30px;
      display: flex;
      justify-items: center;
      align-items: center;
      .phone-title{
        width: 84px;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
    }
    .schools{
      margin-left: 90px;
      margin-top: 30px;
      display: flex;
      justify-items: center;
      align-items: center;
      .schools-title{
        width: 84px;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
    }
    .department{
      margin-left: 90px;
      margin-top: 30px;
      display: flex;
      justify-items: center;
      align-items: center;
      .department-title{
        width: 84px;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
    }
    .posts{
      margin-left: 90px;
      margin-top: 30px;
      display: flex;
      justify-items: center;
      align-items: center;
      .posts-title{
        width: 84px;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
    }
    .titles{
      margin-left: 90px;
      margin-top: 30px;
      display: flex;
      justify-items: center;
      align-items: center;
      .titles-title{
        width: 84px;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
    }
    .choose{
      margin-left: 90px;
      margin-top: 30px;
      display: flex;
      justify-items: center;
      align-items: center;
      .choose-title{
        width: 84px;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
      .choose-one{
        width: 184px;
        height: 40px;
        outline: none;
        background: #FCFCFC;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0px 10px 0px 10px ;
      }
      .select-one{
        width: 184px;
        height: 40px;
      }
      .select-two{
        width: 184px;
        height: 40px;
        margin-left: 20px;
      }
    }
    .address{
      margin-left: 90px;
      margin-top: 30px;
      display: flex;
      justify-items: center;
      align-items: center;
      .address-title{
        width: 84px;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
    }
    .qualification{
      margin-left: 90px;
      margin-top: 30px;
      display: flex;
      justify-items: center;
      align-items: flex-start;
      .qualification-title{
        width: 84px;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
    }
    .save{
      width: 387px;
      height: 42px;
      font-size: 16px;
      line-height: 42px;
      text-align: center; 
      color: #FFFFFF;
      margin-left: 170px;
      margin-top: 60px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
.input-view{
  width: 387px;
  height: 40px;
  background: #FCFCFC;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 15px;
  line-height: 40px;  
  color: #666666;
  outline: none;
}
/**选择图片** */
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 48px;
    color: #8c939d;
    width: 387px;
    height: 213px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;  
    line-height: 213px;
    text-align: center;
  }
  .avatar {
    width: 387px;
    height: 213px;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;  
    display: block;
  }
  .zt{
    width: 80%;
    height: 80px;
    border: 1px solid #DDD;
    margin-top: 20px;
    margin-left: 90px;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    .js{
      width: 80px;
      height: 35px;
      background-color: #409EFF;
      font-size: 14px;
      line-height: 35px;
      text-align: center;
      border-radius: 5px;
      margin-left: 20px;
      color: #FFFFFF;
    }
    .yy{
      width: 70%;
      height: 40px;
      margin-left: 10px;
      font-size: 14px;
      line-height: 40px;
      color: #333;
    }
    .ztnr{
      position: absolute;
      right: 20px;
      margin-top: 20px;
      .zt1{
        font-size: 14px;
        text-align: right;
        color: #333;
      }
      .sj{
        margin-top: 5px;
        font-size: 12px;
        text-align: center;
        color: #666;
      }
    }
  }
</style>