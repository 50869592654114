<template>
  <div class="select-box select_my_use" @click="activeFn">
    <div class="select-right select_my_use">{{placeholder ? placeholder : txt}}</div>
    <div class="select-list select_my_use" v-if="active">
        <div class="box select_my_use"></div>
        <div class="content select_my_use">
            <div class="list select_my_use">
            <div class="select-part select_my_use" v-for="(item,index) in list" :key="index" @click="chooseMethod(index,item.dictLabel,item.dictValue)">{{item.dictLabel}}</div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
/**全局计数器，所有组件都用。 点击+1， 最高的是当前组件。其他组件关闭弹窗**/
let _active_select_index = 0 
export default {
  name: 'my-select',
  props:{
      list:{
          type:Array,
          default:[]
      },
      placeholder:{
          type:String,
          default:'请选择'
      },
      typeS:{
        type:Number,
        default:0
      },
  },
  components: {},
  data() {
    return {
      txt:'',
      active: false, // 弹窗是否展示
      active_select_index: 0, // 当前组件计数器
      eventFn: e => {
        const className = e.target.className
        /**如果不是最高层级的组件 关闭弹窗**/ 
        if (this.active_select_index !== _active_select_index) {
          this.active = false
        }
        /**如果有select_my_use类名 点击的不是该组件 需要关闭弹窗**/ 
        if (className.indexOf('select_my_use') === -1) {
          this.active = false
        }
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    document.addEventListener('click', this.eventFn)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.eventFn)
  },
  methods: {
    activeFn() {
      /**点击组件 弹窗显示隐藏**/ 
      this.active = !this.active
      /**全局计数器+1**/ 
      _active_select_index += 1
      /**当前组件计数器 赋值**/ 
      this.active_select_index = _active_select_index
    },
    chooseMethod(index,title,code) {
      this.txt = title
      if (this.typeS == 0) {
        this.$emit('chooseMethod', code)
      }else if (this.typeS == 1) {
        this.$emit('chooseMethod1',index,title,code)
      }else{
        this.$emit('chooseMethod2',index,title,code)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-box{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    .select-right {
        width: calc(100% - 20px);
        height: 100%;
        position: relative;
        margin-left: 10px;
        line-height: 40px;
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        &::after {
            content: '';
            position: absolute;
            width: 10px;
            height: 6px;
            right: 0;
            top: 16px;
            background: url('../../../../assets/users/subscript.png') center/100% no-repeat;
        }
        &.active::after {
            background: url('../../../../assets/users/subscript.png') center/100% no-repeat;
        }
        &.gray {
            color: #a8a8a8;
        }
    }
    .select-list{
        width: 100%;
        position: absolute;
        left: 0;
        top: 40px;
        .box{
            margin-left: 20px;
            width:0;
            height:0;
            border:10px solid;
            border-color:transparent transparent #f9f9f9 transparent ;
        }
        .content{
            padding: 10px 0 10px 0;
            background-color: #f9f9f9;
            border-radius: 4px;
            .list {
                width: 100%;
                max-height: 200px;
                border-radius: 4px;
                overflow-y: auto;
                z-index: 9;
                -ms-overflow-style: none;
                &::-webkit-scrollbar {
                    display: none;
                }
                .select-part {
                    height: 40px;
                    line-height: 40px;
                    padding-left: 20px;
                    background-color: #f9f9f9;
                    color: #666;
                    font-size: 16px;
                    cursor: pointer;
                    &:hover {
                        color: #333;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>
